<template>

  <div class="workflow__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ article.title }}
            </h1>
            <p class="log_info">
              Created by {{ article.createdBy ? article.createdBy.name : '' }} on {{ dateFormatWithTime(article.createdAt) }} <br>Last updated on {{ dateFormatWithTime(article.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="light"
          class="text-primary"
          :to="{ name: 'app-articles-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana SG</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>
    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230">
              Icon
            </b-th>
            <b-td class="mt-50">
              <b-img
                v-if="article.icon"
                :src="article.icon"
                width="60"
                height="60"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Category
            </b-th>
            <b-td class="mt-50">
              {{ article.category ? article.category.name : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Content
            </b-th>
            <b-td class="mt-50">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="article.content" />
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Status
            </b-th>
            <b-td class="mt-50">
              <span
                class="text-capitalize"
                :class="article.status == 'active' ? 'text-success' : ''"
              >
                {{ article.status }}
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBTooltip, BAlert, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      canViewThisAction,
      article: {},
    }
  },
  created() {
    this.$http.get(`customer/articles/${this.$route.params.id}/show`)
      .then(response => {
        document.title = `${response.data.title} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
        this.article = response.data || {}
        // this.makeHoverItems()
        store.commit('breadcrumb/REMOVE_BREADCRUMB')
        const newMeta = {
          active: true,
          text: `${this.article.title}`,
        }
        store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {

  },
}
</script>
